// eslint-disable-next-line import/no-anonymous-default-export
export default {
  1: {
    id: 1,
    name: "Main net",
  },
  3: {
    id: 3,
    name: "Ropsten",
  },
};
