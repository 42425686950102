import { createContext, useContext, useEffect } from "react";
import { useAccountProfile } from "../hooks/useAccountProfile";
import useWallet from "../hooks/useWallet";
import { logoutUserProfile } from "../utils/helpers";
import { clearToken } from '../utils/http';
import { useGlobalState } from "./Global";

/***
 * @typedef {{
 *   id: number,
 *   address : string,
 *   name: null | string,
 *   rate: null | string,
 *   bio: null | string,
 *   slug: null | string,
 *   socials: null,
 *   twitter: null | string,
 *   portfolio_url: null | string,
 *   email: null | string,
 *   avatar: null | string | Object,
 *   background: null | string,
 *   created_by: null | string,
 *   updated_by: null | string,
 *   created_at: null | string,
 *   updated_at: null | string,
 *   published_at: null | string,
 *   artistId: null | number,
 *   agentId: null | number,
 *   agent: null | Object,
 *   artist: null | Object
 * }} Account
 *
 * @typedef {{
 *   accountExist : boolean,
 *   accessToken : string,
 *   isAuthorized : boolean,
 *   account : Account
 * }} Profile
 */
const ProfileContext = createContext({
  profile: undefined,
  setProfile: () => null,
  logout: () => null,
});

/***
 * @return {{logout: (function(): void), profile: null | Profile, setProfile: (function(Partial<Profile>): void )}}
 */
export const useProfile = () => useContext(ProfileContext);


export const ProfileProvider = ({ children }) => {

  const {
    web3: { deactivate, active, connector }, activatingConnector, setActivatingConnector, connectorName,
  } = useGlobalState();

  const [profile, setProfile, fetchProfile] = useAccountProfile();
  const { setWallet } = useWallet();

  const logout = () => {
    deactivate();
    clearToken();
    if (profile.isAuthorized) {
      logoutUserProfile().catch();
    }

    setProfile(prev => ({
      ...prev,
      ...{ isAuthorized: false, account: { avatar: { url: "" } } },
    }));

  };

  // handle logic to recognize the connector currently being activated
  useEffect(() => {
    if (activatingConnector) {
      //fetch account details from backend and request signature
      if (profile?.isAuthorized !== true)
        fetchProfile().catch();

      //if auth reset activating and set wallet
      if (profile?.isAuthorized === true) {

        setActivatingConnector(undefined);

        if (active) setWallet(connectorName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatingConnector, connector, profile?.isAuthorized]);

  return (
    <ProfileContext.Provider value={{ profile, setProfile, logout }}>
      {children}
    </ProfileContext.Provider>
  );
};
